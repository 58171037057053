// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "reorderLink"
  ];

  initialize() {}

  connect() {}

  reorder_drug_sales = async (_event) => {
    let checked = document.querySelectorAll('input[name="drug_sales"]:checked');
    let checked_arr = Array.from(checked);
    let checked_values = checked_arr.map((checkbox) => checkbox.value);
    let base_url = _event.target.dataset["baseUrl"];
    console.log(base_url);
    console.log(checked_values);
    _event.target.href = `${base_url}&reorder=${checked_values.join(",")}`
  }
}
