// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus";
import {styleGuideSetup} from "../../styleguide/setup";

export default class extends Controller {
  static targets = [
    'form',
    'caseVetFirstName',
    'caseVetLastName',
    'caseVetEmail',
    'caseVetPhoneNumber',
    'newVetForm',
    'usingNonFidocurePathLab',
    'permissionToContactRefLab',
    'parentState',
    'allCancerHeader',
    'therapyOnlyHeader',
    'serviceType',
    'dnaCard',
    'fetchCard',
    'selectedDna',
    'selectedFetch',
    'informationUsingNonFidocurePathLabFalse',
    'informationUsingNonFidocurePathLabTrue',
    'labInfo',
    'labName',
    'submittingClinic',
    'submittingClinicSelect',
    'submittingClinicDetails',
    'submittingClinicName',
    'submittingClinicEmail',
    'noBiopsyNote',
    'partnerReferenceLabMessage',
    'partnerAntechMessage',
    'otherAntechMessage',
    'otherReferenceLabTextField',
    'otherReferenceLabMessage',
    'formSubmit',
    'otherBreedTypeTextField',
    'otherTumorTypeTextFieldContainer',
    'otherTumorTypeTextField',
    'osaNote',
    'biopsyLocationField',
    'sampleDescriptionField',
    'therapyOnlyPaymentOptions',
    'dnaSequencingPaymentOptions',
    'dnaSequencingPayeeClinic',
    'dnaSequencingPayeeParent',
    'targetedTherapyOnlyPayeeNote',
    'ddrPayeeClinic',
    'ddrPayeeParent',
    'ddrPayeeParentNote',
    'formCompletedByOptions',
    'supportStaffFirstName',
    'supportStaffLastName',
    'supportStaffEmail',
    'submitterVetFalse',
    'submitterVetTrue',
    'submitterInfo',
    'industryDiscountContainer',
    'closeReasonPassedAwayContainer',
    'closeReasonOtherContainer',
    'payNowOptions',
    'payNowOptionsTrue',
    'payNowOptionsFalse',
    'dnaSequencingPayLaterNote',
    'payNowNote',
    'ctDnaOptions',
    'ctDnaOptionsBlood',
    'ctDnaOptionsTissue',
    'hasGrossDiseaseField',
    'grossDiseaseTypeField',
    'hasGrossDiseaseFieldNo',
    'hasGrossDiseaseFieldYes',
    'grossDiseaseTypeFieldPrimary',
    'grossDiseaseTypeFieldMetastatic',
    'grossDiseaseTypeFieldUnknown',
    'noGrossNote',
    'mainChemoTherapyField',
    'otherChemoTherapyField',
    'nsaidsField',
    'supplementsField',
    'otherField',
    'surgeryOutcomeField',
    'surgeryOutcomeComplete',
    'surgeryOutcomeIncomplete',
    'surgeryOutcomeUnknown',
  ];

  initialize() {
    this.set_main_treatment_checkbox_groups();
    window.addEventListener('pageshow', (event) => {
      console.log("pageshow");
      let uid = document.getElementById("case_uid").value;
      console.log(uid);
      this.handle_case_already_persisted(uid);
    });
  }

  connect() {
    let that = this;
    if(this.serviceTypeTarget.value === "all_cancer"){
      setTimeout(function() {that.dnaCardTarget.click()}, 400);
    } else if (this.serviceTypeTarget.value === "therapy_only") {
      setTimeout(function() {that.fetchCardTarget.click()}, 400);
    }
  }

  handle_case_already_persisted = async (uid) => {
    const response = await fetch("/cases/exists", {
      method: 'post',
      body: JSON.stringify({'uid': uid}),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    const res = await response.json();
    if(res.is_persisted){
      alert("Your case has been enrolled. For additional changes, please contact operations@fidocure.com");
      window.history.forward();
    }
  }

  resize_text_area = () => {
    const textareaElements = document.querySelectorAll("textarea.materialize-textarea");
    for (let textareaElement of textareaElements) {
      textareaElement.style.height = "2.75rem";
    }
  }

  no_service_selected = _event => {
    _event.target.setCustomValidity('Please select a service');
  }

  service_changed = _event => {
    _event.target.setCustomValidity('');
  }

  changePayee = _event => {
    const radio = _event.target;
    this.show_hide_pay_now();
    this.show_hide_payment_notes();
  }

  show_hide_pay_now = () => {
    let hide_pay_now_sample_type = false;
    try {
      hide_pay_now_sample_type = this.ctDnaOptionsBloodTarget.checked;
    } catch (e) {}
    let hide_pay_now_payee = false;
    try {
      if(this.is_visible(this.dnaSequencingPayeeClinicTarget) && !this.dnaSequencingPayeeClinicTarget.checked){
        hide_pay_now_payee = true
      }
    } catch (e) {}
    try {
      if(this.is_visible(this.ddrPayeeClinicTarget) && !this.ddrPayeeClinicTarget.checked){
        hide_pay_now_payee = true
      }
    } catch (e) {}
    if (hide_pay_now_sample_type || hide_pay_now_payee) {
      this.hide_target_input("payNowOptions")
      this.clear_unrequire_radio_button("payNowOptionsFalse")
      this.clear_unrequire_radio_button("payNowOptionsTrue")
    } else {
      this.show_target_input("payNowOptions")
      this.make_target_input_required("payNowOptionsFalse")
      this.make_target_input_required("payNowOptionsTrue")
    }
  }

  show_hide_payment_notes = () => {
    try {
      if (this.is_visible(this.ddrPayeeClinicTarget)) {
        if(this.ddrPayeeClinicTarget.checked && this.payNowOptionsTrueTarget.checked) {
          this.hide_target_input("ddrPayeeParentNote")
          this.formSubmitTarget.innerHTML = "Submit New Case and Proceed to Payment"
        } else if (this.ddrPayeeParentTarget.checked){
          this.show_target_input("ddrPayeeParentNote")
          this.formSubmitTarget.innerHTML = "Submit New Case"
        }
      }
    } catch (e) {}

    if (this.is_visible(this.payNowOptionsFalseTarget)) {
      if(this.payNowOptionsFalseTarget.checked) {
        if(this.serviceTypeTarget.value === "all_cancer"){
          this.show_target_input("dnaSequencingPayLaterNote")
        } else if (this.serviceTypeTarget.value === "therapy_only") {
          this.hide_target_input("dnaSequencingPayLaterNote")
        }
        this.hide_target_input("payNowNote")
      } else if(this.payNowOptionsTrueTarget.checked) {
        this.show_target_input("payNowNote")
        this.hide_target_input("dnaSequencingPayLaterNote")
      }
    }
  }

  changePayNow = _event => {
    const radio = _event.target;
    if (radio.value === "true") {
      this.formSubmitTarget.innerHTML = "Submit New Case and Proceed to Payment"
    } else { // false
      this.formSubmitTarget.innerHTML = "Submit New Case"
    }
    this.show_hide_payment_notes();
  }

  update_vet_info = _event => {
    const select = _event.target;

    if (select.value === "new") {
      this.show_target_input("newVetForm");
      this.make_target_input_required("caseVetFirstName");
      this.make_target_input_required("caseVetLastName");
      this.make_target_input_required("caseVetEmail");
      this.make_target_input_required("caseVetPhoneNumber");
    }
    else {
      this.hide_target_input("newVetForm");
      this.make_target_input_unrequired("caseVetFirstName");
      this.make_target_input_unrequired("caseVetLastName");
      this.make_target_input_unrequired("caseVetEmail");
      this.make_target_input_unrequired("caseVetPhoneNumber");
    }
    this.resize_text_area()
  }

  update_case_info = _event => {
    let target = _event.target;
    this.make_target_input_required("submitterVetFalse")
    this.make_target_input_required("submitterVetTrue")
    while(!target.classList.contains("service-card")){
      target = target.parentNode
    }
    if (target.classList.contains("dna-card")) {
      this.serviceTypeTarget.value = "all_cancer"
      this.selectedDnaTarget.style.visibility = "visible";
      this.selectedFetchTarget.style.visibility = "hidden";
      try {
        // this causes an error when ctDNA is not enabled, in which case we show the entire tissue flow
        this.ctDnaOptionsTarget
      } catch (error) {
        this.show_tissue_flow()
      }
      this.show_hide_pay_now();
      this.show_target_input("allCancerHeader");
      this.hide_target_input("therapyOnlyHeader");
      this.hide_target_input("therapyOnlyPaymentOptions");
      this.show_target_input("formCompletedByOptions");
      this.show_target_input("ctDnaOptions");
      this.make_target_input_required("ctDnaOptionsTissue");
      this.make_target_input_required("ctDnaOptionsBlood");
      this.clear_unrequire_radio_button("ddrPayeeClinic")
      this.clear_unrequire_radio_button("ddrPayeeParent")
      this.hide_target_input("ddrPayeeParentNote");

      if(this.informationUsingNonFidocurePathLabTrueTarget.checked) {
        this.performance_updates(this.informationUsingNonFidocurePathLabTrueTarget)
      }
      this.formSubmitTarget.innerHTML = "Submit New Case"
    }
    else {
      this.serviceTypeTarget.value = "therapy_only"
      this.selectedDnaTarget.style.visibility = "hidden";
      this.selectedFetchTarget.style.visibility = "visible";
      this.hide_target_input("allCancerHeader");
      this.show_target_input("therapyOnlyHeader");
      this.hide_target_input("usingNonFidocurePathLab");
      this.hide_target_input("permissionToContactRefLab");
      this.show_target_input("therapyOnlyPaymentOptions");
      this.hide_target_input("dnaSequencingPaymentOptions");
      this.show_target_input("formCompletedByOptions");
      this.hide_target_input("sampleDescriptionField");
      this.hide_target_input("biopsyLocationField");
      this.hide_target_input("ctDnaOptions");
      this.clear_unrequire_radio_button("ctDnaOptionsTissue");
      this.clear_unrequire_radio_button("ctDnaOptionsBlood");

      this.make_target_input_required("ddrPayeeClinic")
      this.make_target_input_required("ddrPayeeParent")

      this.clear_unrequire_radio_button("dnaSequencingPayeeClinic")
      this.clear_unrequire_radio_button("dnaSequencingPayeeParent")
      this.hide_target_input("dnaSequencingPayLaterNote");

      this.make_target_input_unrequired("informationUsingNonFidocurePathLabFalse");
      this.make_target_input_unrequired("informationUsingNonFidocurePathLabTrue");
      this.make_target_input_unrequired("labName");
      this.hide_target_input("labInfo");
      this.make_target_input_unrequired("labName");
      this.hide_target_input("noBiopsyNote");
      this.hide_target_input("allCancerHeader");
      this.hide_blood_flow()
      this.show_hide_pay_now();
    }
    this.show_hide_payment_notes();
    this.show_target_input("industryDiscountContainer")
  }

  update_sample_type = _event => {
    const radio = _event.target;
    this.show_hide_pay_now()
    if (radio.value === "Tissue") {
      this.show_tissue_flow()
    }
    else {
      this.hide_tissue_flow()
      this.show_blood_flow()
    }
  }

  show_tissue_flow = () => {
    this.show_target_input("usingNonFidocurePathLab")
    this.show_target_input("permissionToContactRefLab")
    this.show_target_input("biopsyLocationField")
    this.show_target_input("sampleDescriptionField")
    this.show_target_input("dnaSequencingPaymentOptions");
    this.hide_target_input("therapyOnlyPaymentOptions")
    this.make_target_input_required("informationUsingNonFidocurePathLabFalse");
    this.make_target_input_required("informationUsingNonFidocurePathLabTrue");
    this.make_target_input_required("dnaSequencingPayeeClinic")
    this.make_target_input_required("dnaSequencingPayeeParent")
    this.hide_blood_flow()
  }

  hide_tissue_flow = () => {
    this.hide_target_input("usingNonFidocurePathLab")
    this.hide_target_input("permissionToContactRefLab")
    this.hide_target_input("biopsyLocationField")
    this.hide_target_input("sampleDescriptionField")
    this.hide_target_input("dnaSequencingPaymentOptions")
    this.show_target_input("therapyOnlyPaymentOptions")
    this.clear_unrequire_radio_button("informationUsingNonFidocurePathLabFalse");
    this.clear_unrequire_radio_button("informationUsingNonFidocurePathLabTrue");
    this.make_target_input_unrequired("dnaSequencingPayeeClinic")
    this.make_target_input_unrequired("dnaSequencingPayeeParent")
    this.hide_target_input("labInfo")
    this.hide_target_input("noBiopsyNote")
    this.make_target_input_unrequired("labName");
  }

  show_blood_flow = () => {
    this.show_target_input("hasGrossDiseaseField")
    this.make_target_input_required("hasGrossDiseaseFieldYes")
    this.make_target_input_required("hasGrossDiseaseFieldNo")
  }

  hide_blood_flow = () => {
    this.hide_target_input("hasGrossDiseaseField")
    this.hide_target_input("grossDiseaseTypeField");
    this.hide_target_input("noGrossNote")
    this.clear_unrequire_radio_button("hasGrossDiseaseFieldYes")
    this.clear_unrequire_radio_button("hasGrossDiseaseFieldNo")
    this.clear_unrequire_radio_button("grossDiseaseTypeFieldPrimary")
    this.clear_unrequire_radio_button("grossDiseaseTypeFieldMetastatic")
    this.clear_unrequire_radio_button("grossDiseaseTypeFieldUnknown")
  }

  update_case_info_lab = _event => {
    const radio = _event.target;
    this.performance_updates(radio);
  }

  performance_updates = radio => {
    if (radio.value === "true") {
      this.show_target_input("labInfo");
      this.make_target_input_required("labName");
      this.hide_target_input("noBiopsyNote");
    }
    else {
      this.hide_target_input("labInfo");
      this.make_target_input_unrequired("labName");
      this.show_target_input("noBiopsyNote");
    }
  }

  update_case_info_lab_message = _event => {
    const select = _event.target;

    this.hide_target_input("partnerReferenceLabMessage");
    this.hide_target_input("otherReferenceLabTextField");
    this.hide_target_input("otherReferenceLabMessage")

    if (select.value === 'Other') {
      this.show_target_input("otherReferenceLabTextField");
      this.show_target_input("otherReferenceLabMessage");
      this.hide_submitting_clinic_details();
    } else if(select.value.toLowerCase().includes("antech")) {
      this.hide_target_input("otherReferenceLabMessage")
      this.show_target_input("submittingClinicSelect")
      this.make_target_input_required("submittingClinic");
      this.update_submitting_clinic();
    } else {
      this.hide_submitting_clinic_details();
      this.show_target_input("partnerReferenceLabMessage");
      this.hide_target_input("otherReferenceLabTextField");
      document.getElementById("case_other_lab_name").value = "";
      this.hide_target_input("partnerAntechMessage");
      this.hide_target_input("otherAntechMessage");
      this.hide_target_input("partnerAntechMessage")
      this.hide_target_input("otherAntechMessage")
      this.hide_target_input("otherReferenceLabMessage")
      this.hide_target_input("submittingClinicSelect")
      this.make_target_input_unrequired("submittingClinic");
    }
  }

  update_other_lab_message = _event => {
    const other = _event.target;

    if (!other.value.toLowerCase().includes("antech")) {
      this.hide_target_input("partnerAntechMessage")
      this.hide_target_input("otherAntechMessage")
      this.show_target_input("otherReferenceLabMessage")
      this.hide_target_input("submittingClinicSelect")
    }
  }

  update_submitting_clinic = () => {
    if(this.submittingClinicTarget.value === "other"){
      this.show_submitting_clinic_details();
      this.show_target_input("otherAntechMessage")
      this.hide_target_input("partnerAntechMessage")
    } else {
      this.hide_submitting_clinic_details();
      this.show_target_input("partnerAntechMessage")
      this.hide_target_input("otherAntechMessage")
    }
  }
  show_submitting_clinic_details = () => {
    this.show_target_input("submittingClinicDetails");
    this.make_target_input_required("submittingClinicName");
    this.make_target_input_required("submittingClinicEmail");
  }
  hide_submitting_clinic_details = () => {
    this.hide_target_input("submittingClinicDetails");
    this.make_target_input_unrequired("submittingClinicName");
    this.make_target_input_unrequired("submittingClinicEmail");
  }
  update_surgery = _event => {
    const select = _event.target;
    if (select.value === 'Yes' ) {
      this.show_target_input('surgeryOutcomeField')
      this.make_target_input_required('surgeryOutcomeComplete')
      this.make_target_input_required('surgeryOutcomeIncomplete')
      this.make_target_input_required('surgeryOutcomeUnknown')
    } else {
      this.hide_target_input('surgeryOutcomeField')
      this.make_target_input_unrequired('surgeryOutcomeComplete')
      this.make_target_input_unrequired('surgeryOutcomeIncomplete')
      this.make_target_input_unrequired('surgeryOutcomeUnknown')
    }
  }

  update_other_tumor_type_display = _event => {
    const select = _event.target;
    this.hide_target_input("otherTumorTypeTextFieldContainer");
    this.make_target_input_unrequired("otherTumorTypeTextField");
    this.hide_target_input("osaNote");

    if (select.value === 'Other (or multiple)' ) {
      this.show_target_input("otherTumorTypeTextFieldContainer");
      this.make_target_input_required("otherTumorTypeTextField");
    }
    if (select.value === 'Osteosarcoma' ) {
      this.show_target_input("osaNote");
    }
  }

  show_hide_submitter = _event => {
    const radio = _event.target;
    if (radio.value === "support_staff") {
      this.show_target_input("submitterInfo");
      this.make_target_input_required("supportStaffFirstName");
      this.make_target_input_required("supportStaffLastName");
      this.make_target_input_required("supportStaffEmail");
    }
    else {
      this.hide_target_input("submitterInfo");
      this.make_target_input_unrequired("supportStaffFirstName");
      this.make_target_input_unrequired("supportStaffLastName");
      this.make_target_input_unrequired("supportStaffEmail");
    }
  }

  show_hide_gross_disease_type = _event => {
    const radio = _event.target;
    if (radio.value === "Yes") {
      this.show_target_input("grossDiseaseTypeField");
      this.make_target_input_required("grossDiseaseTypeFieldPrimary")
      this.make_target_input_required("grossDiseaseTypeFieldMetastatic")
      this.make_target_input_required("grossDiseaseTypeFieldUnknown")
      this.hide_target_input("noGrossNote")
    }
    else {
      this.hide_target_input("grossDiseaseTypeField");
      this.clear_unrequire_radio_button("grossDiseaseTypeFieldPrimary")
      this.clear_unrequire_radio_button("grossDiseaseTypeFieldMetastatic")
      this.clear_unrequire_radio_button("grossDiseaseTypeFieldUnknown")
      this.show_target_input("noGrossNote")
    }
  }

  set_main_treatment_checkbox_groups = () => {
    let checkboxes = document.getElementsByName("case[main_cancer_treatment][]");
    let any_checked = Array.from(checkboxes).some((el) => {
      return el.checked
    })
    if (any_checked) {
      checkboxes[0].required = false;
      checkboxes[0].setCustomValidity("");
    } else {
      checkboxes[0].required = true;
      checkboxes[0].setCustomValidity("Please select a cancer treatment");
    }
  }
  set_chemo_checkbox_groups = (unrequired=false) => {
    let checkboxes = document.getElementsByName("case[chemotherapy][]");
    let any_checked = Array.from(checkboxes).some((el) => { return el.checked })
    if(any_checked || unrequired){
      checkboxes[0].required = false;
      checkboxes[0].setCustomValidity("");
    } else {
      checkboxes[0].required = true;
      checkboxes[0].setCustomValidity("Please select a type of chemotherapy (or Unknown)");
    }
  }

  update_main_cancer_treatment = _event => {
    let checkbox = _event.target;
    switch (checkbox.value) {
      case "Traditional chemotherapy":
        this.toggle_target_input("mainChemoTherapyField", checkbox.checked);
        this.set_chemo_checkbox_groups(!checkbox.checked);
        break;
      case "NSAIDs":
        this.toggle_target_input("nsaidsField", checkbox.checked, false);
        break;
      case "Supplements":
        this.toggle_target_input("supplementsField", checkbox.checked, false);
        break;
      case "Other":
        this.toggle_target_input("otherField", checkbox.checked, true);
        break;
    }
    this.set_main_treatment_checkbox_groups();
  }

  update_main_chemotherapy = _event => {
    let checkbox = _event.target;
    switch (checkbox.value) {
      case "Other":
        this.toggle_target_input("otherChemoTherapyField", checkbox.checked, true)
        break;
    }
    this.set_chemo_checkbox_groups();
  }

  update_country = async _event => {
    const select = _event.target;
    const response = await fetch("/utils/find_states", {
      method: 'post',
      body: JSON.stringify({'country_id': select.value}),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    const states = await response.json();

    let select_instance = M.FormSelect.getInstance(this.parentStateTarget);
    select_instance.destroy();
    let states_el = document.getElementById('case_pet_parent_state');
    states_el.innerHTML = states.map((st) => {return `<option value="${st[0]}">${st[1]}</option>`});
    let instances = M.FormSelect.init(states_el, {dropdownOptions: states});
  }

  update_parent_phone = async _event => {
    let el = _event.target;
    el.classList.remove("invalid", "valid");
    let phone = el.value;
    let country = document.getElementById("case_pet_parent_country").value;
    let response = await fetch("/utils/validate_phone", {
      method: 'post',
      body: JSON.stringify({"country_id": country, "phone": phone}),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    let res = await response.json();
    if(res["valid"]) {
      el.classList.add("valid");
      el.classList.remove("invalid");
    } else {
      el.classList.add("invalid");
      el.classList.remove("valid");
    }
  }

  form_submit = _event => {
    _event.preventDefault();
    let form = document.getElementById("case_enrollment_form");
    if(form.reportValidity()) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "case_submitted",
        vet_email: this.caseVetEmailTarget.value,
        support_staff_email: this.supportStaffEmailTarget.value,
        parent_email: document.getElementById("case_pet_parent_email").value
      })
      form.submit();
    }
  }

  update_case_close_reason = _event => {
    const radio = _event.target;
    if (radio.value === "passed_away") {
      this.slow_show_target_input("closeReasonPassedAwayContainer");
      this.slow_hide_target_input("closeReasonOtherContainer");
    }
    else {
      this.slow_show_target_input("closeReasonOtherContainer");
      this.slow_hide_target_input("closeReasonPassedAwayContainer");
    }
  }

  clear_radio_button = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].checked = false;
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  make_target_input_required = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].required = true;
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  make_target_input_unrequired = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].required = false;
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  clear_unrequire_radio_button = inputTargetName => {
    this.make_target_input_unrequired(inputTargetName)
    this.clear_radio_button(inputTargetName)
  }

  show_target_input = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].classList.remove('hide');
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  hide_target_input = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].classList.add('hide');
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  toggle_target_input = (inputTargetName, show, toggle_required= false, input_type='input') => {
    try {
      if (inputTargetName !== null) {
        if (show) {
          this.show_target_input(inputTargetName)
          if(toggle_required) {
            this[inputTargetName + "Target"].querySelector(input_type).required = true;
          }
        } else {
          this.hide_target_input(inputTargetName)
          if(toggle_required) {
            this[inputTargetName + "Target"].querySelector(input_type).required = false;
          }
        }
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  slow_show_target_input = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].classList.remove('slow_hide');
        this[inputTargetName + "Target"].classList.add('slow_show');
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  slow_hide_target_input = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].classList.remove('slow_show');
        this[inputTargetName + "Target"].classList.add('slow_hide');
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  is_visible = inputTarget => {
    return inputTarget.offsetParent !== null;
  }
}
